


@font-face {
    font-family: 'CaslonIonic';
    src:
    url(../fonts/CaslonIonic-Regular-Web.woff2) format("woff2"),
    url(../fonts/CaslonIonic-Regular-Web.woff) format("woff");
}

@font-face {
    font-family: 'CaslonIonic';
    font-style: italic;
    src:
    url(../fonts/CaslonIonic-RegularItalic-Web.woff2) format("woff2"),
    url(../fonts/CaslonIonic-RegularItalic-Web.woff) format("woff");
}

@font-face {
    font-family: 'CaslonIonic';
    font-weight: 300;
    src:
    url(../fonts/CaslonIonic-Light-Web.woff2) format("woff2"),
    url(../fonts/CaslonIonic-Light-Web.woff) format("woff");
}