body {
  @apply antialiased text-offBlack;
}

.generalGrid {
  @apply md:grid grid-cols-12 gap-5;
}

.imageRound {
  @apply rounded-md;
}

.hoverRotate {
  @apply md:group-hover:rotate-[-1deg];
}

media-player {
  @apply !block !border-none !rounded-none !border-transparent !bg-transparent;
}

media-outlet {
  @apply !bg-transparent !w-full !h-full;
}

media-player video {
  @apply !w-full !h-full !object-cover;
}

media-player media-poster img {
  @apply !w-full !h-full;
}

/* media-player media-poster {
  @apply !w-full !h-full;
} */


.loading::after {
  display: inline-block;
  animation: dotty steps(1,end) 1s infinite;
  content: '';
}

@keyframes dotty {
    0%   { content: ''; }
    25%  { content: '.'; }
    50%  { content: '..'; }
    75%  { content: '...'; }
    100% { content: ''; }
}

/* purgecss start ignore */

.twic-blur, .twic-loading {
    opacity: 0;
    will-change: opacity;
    transition: opacity 1s linear, transform .75s ease-out;
}

  .twic-done {
    opacity: 1;
  }


  :where(media-player[data-view-type='video']:not([data-fullscreen])) :where(media-outlet, video, media-poster, div[part='scrim']) {
    @apply rounded-[0px] border-none border-transparent;
  }

/* purgecss end ignore */