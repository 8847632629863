/* purgecss start ignore */

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Skin
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-player[data-view-type='video']) {
  --media-focus-ring: var(--video-focus-ring, 0 0 0 3px rgb(78 156 246));
  border-radius: var(--video-border-radius, 6px);
  background-color: var(--video-bg, black);
}

:where(media-player[data-view-type='video']:not([data-fullscreen])) {
  border-radius: var(--video-border-radius, 6px);
  border: var(--video-border, 1px solid rgb(255 255 255 / 0.1));
}

:where(media-player[data-view-type='video']:not([data-fullscreen]))
  :where(media-outlet, video, media-poster, div[part='scrim']) {
  border-radius: var(--video-border-radius, 6px);
  overflow: hidden;
}

:where(media-community-skin[data-video]) {
  --brand: var(--video-brand, #f5f5f5);
  --media-font-family: var(--video-font-family, sans-serif);
  --media-slider-track-fill-bg: var(--brand);
  --media-menu-radio-check-active-color: var(--brand);
  --media-chapters-progress-bg: var(--brand);
  --media-controls-color: var(--video-controls-color, #f5f5f5);
  color: var(--video-controls-color, #f5f5f5);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Scrim
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-community-skin[data-video] div[part='scrim']) {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  background-color: var(--video-scrim-bg, rgb(0 0 0 / 0.35));
  transition: var(--video-scrim-out-transition, opacity 0.15s ease-out);
}

:where(media-player:not([data-can-play]) media-community-skin[data-video] div[part='scrim']),
:where(media-player:not([data-user-idle]) media-community-skin[data-video] div[part='scrim']) {
  opacity: 1;
  transition: var(--video-scrim-in-transition, opacity 0.15s ease-in);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Controls
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-community-skin[data-video]) :where(div[part='controls']) {
  position: absolute;
  display: flex;
  flex-direction: column;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: var(--video-controls-out-transition, opacity 0.2s ease-out);
}

:where(
    media-player[data-can-play]:not([data-user-idle])
      media-community-skin[data-video]
      div[part='controls']
  ) {
  opacity: 1;
  visibility: visible;
  transition: var(--video-controls-in-transition, opacity 0.2s ease-in);
}

:where(media-community-skin div[part='controls-spacer']) {
  flex: 1 1 0%;
}

:where(media-community-skin[data-video] div[part='controls-group']) {
  align-items: center;
  display: flex;
  margin-bottom: 4px;
  pointer-events: auto;
  z-index: 0;
  padding: 2px 6px;
}

:where(media-community-skin[data-video] div[part='controls-group']:first-child) {
  margin-top: 4px;
  z-index: 50;
}

:where(media-community-skin[data-video] div[part='controls-group']:nth-child(2)) {
  display: flex;
  flex: 1 1 0%;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

/* second last group */
:where(media-community-skin[data-video] div[part='controls-group']:nth-last-child(2)) {
  padding: 0 12px;
  z-index: 10;
  margin-bottom: -16px;
}

:where(media-community-skin[data-video]:not([data-mobile]) div[part='controls-group']:last-child) {
  --media-tooltip-y-offset: 34px;
  z-index: 10;
}

:where(media-community-skin[data-video] [data-media-button]) {
  margin-right: 6px;
}

:where(media-community-skin[data-video] [data-media-button]:last-child) {
  margin-right: 6px;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Title
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-community-skin[data-video] [part='main-title']) {
  display: inline-block;
  font-size: var(--video-title-font-size, 14px);
  font-weight: var(--video-title-font-weight, 500);
  font-family: var(--video-font-family, sans-serif);
  color: var(--video-title-color, #dedede);
  flex: 1 1 0%;
  padding-inline: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

:where(media-community-skin[data-video]) [part='main-title']::before {
  content: '•';
  display: inline-block;
  margin-right: 6px;
  color: var(--video-title-color, #dedede);
}

:where(media-community-skin[data-video]) [part='main-title']:empty::before {
  content: '';
  margin-left: 0;
}

:where(media-player[data-fullscreen] media-community-skin[data-video] [part='main-title']) {
  font-size: var(--video-fullscreen-title-font-size, 16px);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Buttons
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-community-skin[data-video] media-mute-button) {
  margin-left: -4px;
}

:where(media-community-skin[data-video] media-fullscreen-button) {
  margin-right: 8px;
}

:where(media-community-skin[data-mobile][data-video]) {
  --media-button-size: var(--video-mobile-button-size, 32px);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Sliders
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-community-skin[data-video] media-time-slider) {
  flex-grow: 0;
}

:where(media-community-skin[data-video] media-slider-thumbnail) {
  --media-thumbnail-border: var(--video-slider-thumbnail-border, 1px solid #f5f5f5);
  border-radius: var(--video-slider-thumbnail-border-radius, 2px);
}

:where(media-community-skin[data-video] media-slider-value) {
  margin-top: 4px;
  border-radius: var(--video-slider-value-border-radius, 2px);
}

:where(media-community-skin[data-video] [part='chapter-title'] + media-slider-value) {
  margin-top: 0;
}

:where(media-community-skin[data-video] media-time-slider media-slider-value) {
  background-color: var(--video-time-bg, unset);
  text-shadow: -1px -1px 0 #333333, 1px -1px 0 #333333, -1px 1px 0 #333333, 1px 1px 0 #333333;
}

:where(media-community-skin[data-video][data-mobile] media-time) {
  text-shadow: unset;
}

:where(media-community-skin[data-video] media-volume-slider) {
  margin-left: -2px;
  max-width: var(--video-volume-slider-max-width, 80px);
  transition: all 0.15s ease;
  transform: translateX(-2px);
}

:where(media-community-skin[data-video])
  :where(media-mute-button:not([data-hocus]) + media-volume-slider:not([data-interactive])) {
  margin: 0;
  max-width: 0;
}

:where(media-community-skin[data-video] media-volume-slider media-slider-value) {
  bottom: 70px;
}

@media (orientation: landscape) and (pointer: coarse) {
  :where(media-player media-community-skin media-volume-slider) {
    display: none;
  }
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Time
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-community-skin[data-video] media-time[type='current']) {
  margin-right: 2px;
}

:where(media-community-skin[data-video] media-time[type='current'][remainder]) {
  margin-left: 2px;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Captions
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-player[data-preview] media-community-skin[data-video] media-captions) {
  opacity: 0;
}

:where(media-community-skin[data-video] media-captions) {
  z-index: 10;
  transition: var(--video-captions-transition, bottom 0.15s linear);
}

:where(
    media-player[data-fullscreen][data-bp-x='lg'] media-community-skin[data-video] media-captions
  ) {
  bottom: var(--video-large-fullscreen-captions-offset, 54px);
}

:where(media-player:not([data-user-idle]) media-community-skin[data-video] media-captions, ) {
  bottom: var(--video-captions-offset, 72px);
}

:where(
    media-player:not([data-user-idle]) media-community-skin[data-video][data-mobile] media-captions
  ) {
  bottom: var(--video-mobile-captions-offset, 48px);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Chapters
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-community-skin[data-video] [part='chapter-title']) {
  width: 100%;
  text-align: center;
  text-shadow: -1px -1px 0 #212121, 1px -1px 0 #212121, -1px 1px 0 #212121, 1px 1px 0 #212121;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Gestures
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-community-skin[data-video] media-gesture) {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

:where(media-community-skin[data-video] media-gesture[action='seek:-10']) {
  width: var(--video-gesture-seek-width, 20%);
  z-index: 1;
}

:where(media-community-skin[data-video] media-gesture[action='seek:10']) {
  left: unset;
  right: 0;
  width: var(--video-gesture-seek-width, 20%);
  z-index: 1;
}

:where(
    media-player[data-touch] media-community-skin[data-video] media-gesture[action='toggle:paused']
  ) {
  display: none;
}

:where(
    media-player:not([data-touch])
      media-community-skin[data-video]
      media-gesture[action='toggle:user-idle']
  ) {
  display: none;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Time Group
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-community-skin[data-video] [part='time-group']) {
  display: flex;
  align-items: center;
}

:where(media-community-skin[data-video] [part='time-divider']) {
  margin-left: 2px;
  margin-right: 3px;
  color: var(--video-time-divider-color, rgb(224, 224, 224));
}

:where(media-player[data-fullscreen] media-community-skin[data-video] media-time) {
  font-size: var(--video-fullscreen-time-font-size, 16px);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Mobile
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-community-skin[data-video][data-mobile] div[part='controls-group']:nth-last-child(2)) {
  margin-bottom: -20px;
  z-index: 1;
}

:where(media-community-skin[data-video][data-mobile] div[part='controls-group']:last-child) {
  margin-bottom: -12px;
}

:where(
    media-player[data-fullscreen]
      media-community-skin[data-video][data-mobile]
      [part='controls-group']:last-child
  ) {
  margin-bottom: 0;
}

:where(media-community-skin[data-video][data-mobile] div[part='controls-group']) {
  padding: 2px 2px;
}

:where(media-community-skin[data-video][data-mobile])
  :where(
    [data-media-button],
    [data-media-slider]:not(media-time-slider),
    media-time,
    [part='time-divider'],
    [part='main-title']
  ) {
  transition: opacity 0.15s ease;
}

:where(media-player:not([data-started]) media-community-skin[data-video][data-mobile])
  :where([data-media-button] [data-media-slider], [part='time-group'], ) {
  opacity: 0;
  visibility: hidden;
}

:where(media-player[data-preview] media-community-skin[data-video][data-mobile])
  :where(
    [data-media-button],
    [data-media-slider]:not(media-time-slider),
    media-time,
    [part='main-title'],
    [part='time-divider'],
    media-captions
  ) {
  opacity: 0;
}

:where(media-community-skin[data-video][data-mobile] media-time-slider) {
  transition: transform 0.1s linear;
}

:where(media-player[data-preview] media-community-skin[data-video][data-mobile] media-time-slider) {
  --track-height: var(--video-slider-track-height, 12px);
  transform: translateY(-6px);
  transition: transform 0.1s linear;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Mobile
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-community-skin[data-video][data-mobile] media-play-button) {
  border-radius: 100%;
  pointer-events: auto;
  margin-bottom: 2.5%;
  background-color: var(--video-mobile-play-button-bg, rgba(0 0 0 / 0.6));
  width: var(--video-mobile-play-button-size, 40px);
  height: var(--video-mobile-play-button-size, 40px);
  transform: var(--video-mobile-play-button-transform, translateY(25%));
}

:where(media-community-skin[data-video][data-mobile] media-play-button svg) {
  border-radius: 100%;
}

:where(
    media-player:not([data-started])
      media-community-skin[data-video][data-mobile]
      [part='controls-group']:not(:nth-child(2))
  ) {
  opacity: 0;
  visibility: hidden;
}

/* center big play button inside buffering indicator. */
:where(media-community-skin[data-video][data-mobile] media-buffering-indicator) {
  transform: translate(-2px, -4px);
}

:where(media-community-skin [part='start-duration'] media-time[type='duration']) {
  position: absolute;
  right: 8px;
  bottom: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  z-index: 2;
  padding: var(--video-mobile-start-duration-padding, 3px 6px);
  color: var(--video-mobile-start-duration-color, var(--video-controls-color));
  background-color: var(--video-mobile-start-duration-bg, rgba(0 0 0 / 0.64));
}

:where(
    media-player[data-started]
      media-community-skin
      [part='start-duration']
      media-time[type='duration']
  ) {
  display: none;
}

:where(
    media-player:not([data-can-play]) media-community-skin[data-video] media-time[type='duration']
  ) {
  opacity: 0;
}

:where(media-community-skin[data-video][data-mobile] media-time[type='current']) {
  margin-left: 8px;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Fullscreen
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(
    media-player[data-fullscreen]
      media-community-skin[data-video]
      div[part='controls-group']:nth-last-child(2)
  ) {
  margin-bottom: -16px;
}

@media (orientation: portrait) {
  :where(
      media-player[data-iphone][data-fullscreen]
        media-community-skin[data-video]
        div[part='controls-group']:first-child
    ) {
    margin-top: 48px;
    padding-inline: 4px;
  }

  :where(
      media-player[data-iphone][data-fullscreen]
        media-community-skin[data-video]
        div[part='controls-group']:nth-last-child(2)
    ) {
    padding-inline: 8px;
  }

  :where(
      media-player[data-iphone][data-fullscreen]
        media-community-skin[data-video]
        div[part='controls-group']:last-child
    ) {
    margin-inline: 8px;
    margin-bottom: 52px;
  }

  :where(media-player[data-fullscreen] media-community-skin[data-video] media-captions) {
    bottom: 30vh;
  }
}

@media (orientation: landscape) {
  :where(
      media-player[data-iphone][data-fullscreen][data-user-idle]
        media-community-skin[data-video]
        media-captions
    ) {
    bottom: 32px;
  }

  :where(
      media-player[data-fullscreen]
        media-community-skin[data-video]
        div[part='controls-group']:nth-last-child(2)
    ) {
    margin-bottom: -12px;
  }

  :where(
      media-player[data-iphone][data-fullscreen]
        media-community-skin[data-video]
        div[part='controls-group']:last-child
    ) {
    margin-bottom: 12px;
  }
}

/* purgecss end ignore */